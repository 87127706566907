.MuiDataGrid-footerContainer {
    display: none !important;
}

.MuiSvgIcon-root.disabled{
    cursor: text;
    opacity: 0.2;
}

.pagination {
    float: right;
    margin: 20px;
}
