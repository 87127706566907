.save-btn {
    width: 100%;
    margin-top: 20px!important;
}

.inventory table {
    margin-top: 20px;
}

.inventory .inventory-td {
    font-size: 12px;
}

.inventory .inventory-th {
    text-align: left;
    font-size: 14px;
    border: solid 1px #000;
}
/* Apply alternate row colors */
.inventory .inventory-tr:nth-child(even) {
    background-color: #f2f2f2;
}
  
.inventory .inventory-tr:nth-child(odd) {
    background-color: #e6f7ff;
}
  
/* Apply alternate column colors */
.inventory .inventory-td:nth-child(even), .inventory-td:nth-child(even) {
    background-color: #e6f7ff;
}

.inventory .inventory-td:nth-child(even) input {
    background-color: #e6f7ff;
    border: solid 1px #000;
}

.inventory .inventory-td:nth-child(odd), .inventory-th:nth-child(odd) {
    background-color: #b3e0ff;
}

.inventory .inventory-td:nth-child(odd) input {
    background-color: #b3e0ff;
    border: solid 1px #000;
}

.numeric input {
    width: 50px;
}

.price-list-modal {
    overflow-y: auto;
    max-height: 80vh;
}

.help-text span {
    border: solid 1px #333;
    padding: 4px;
    font-size: 10px;
    margin: 2px;
    background-color: #fff;
}

.dialog-title, .dialog-actions {
    background-color: #ccc;
    color: #fff;
}

.generic-text {
    color: #264653;
}

.qty-text {
    color: #2a9d8f;
}

.name-text {
    color: #e9c46a;
}

.purchase-price-text {
    color: #f4a261;
}

.sales-price-text {
    color: #e76f51;
    font-weight: bold;
}

.purchase-rate-text {
    color: #778da9;
}

.textarea-container {
    width: 100%;
}

.textarea-container textarea {
    width: 100%;
}

.purchase-table {
    margin-top: 20px;
}

.purchase-table th{
    text-align: left;
    border: 1px solid #ddd;
    padding: 0 10px;
}

.purchase-table td {
    padding: 0 10px;
}
