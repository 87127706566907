.phone-input-container {
    display: inline-grid;
    width: 100%;
    grid-row-gap: 10px;
    margin-top: 80px;
}

.phone-number-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 400px;
    padding: 20px;
}

.btn-login, .btn-signup, .btn-verify-otp {
    width: 100%;
    height: 40px;
    line-height: 35px;
    display: block;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #8FC0A9;
    margin-top: 20px;
}

.btn-login:hover,
.btn-signup:hover,
.btn-verify-otp {
  opacity: 0.8;
}

.react-tel-input .form-control {
    width: 100%;
}

.login-email {
    margin: 40px auto;
    width: 300px;
}

.login-email input, .login-email button {
    width: 100%;
    margin: 4px;
    height: 30px;
}

.login-email button {
    padding: 0;
}

.login-email a {
    padding: 0;
    cursor: pointer;
    margin-top: 25px;
    display: block;
}